<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-sm-5">
        <div class="breadcrumb">
          <h1>Modificar Internacion <span class="h4 pl-2"> N°: {{numero}}</span></h1>
        </div>
      </div>
      <!-- aqui va la transicion con la barra informativa superior -->
      <transition name="fade">
        <div class="col-sm-7 navbar-reubicado">
          <div class="row">
            <div class="col-sm-12 py-2 bg-sky-light text-white">
              <div class="row">
                <div class="col-2">
                   <b>Edad:</b> {{dataPaciente.edad}} años
                </div>
                <div class="col-">
                  <b>H.C:</b> {{dataPaciente.numero}}
                </div>
                <div class="col-4" v-show="dataPaciente.contacto">
                  <p style="font-size: 12px">Contacto 1: {{ dataPaciente.contacto }} </p>
                </div>
                <div class="col-4">
                  <p style="font-size: 12px" v-if="dataPaciente.familiar">
                    Familiar 1:
                    {{ dataPaciente.familiar }} </p>
                </div>
                <div
                  v-if="dataPaciente.tipo_seguro==='Conjunto'"
                  class="col-sm-3 px-1"
                >
                  <b>Seguro:</b> {{dataPaciente.cobertura}}
                  <span v-if="dataPaciente.tipo_familiar">
                    ({{dataPaciente.tipo_familiar}})
                  </span>
                </div>
                <div
                  v-if="dataPaciente.numero_poliza || dataPaciente.cod_asegurado"
                  class="col-sm-2 px-1"
                >
                  <template v-if="dataPaciente.numero_poliza">
                    <b>N° Poliza: </b>{{dataPaciente.numero_poliza}}
                  </template>
                  <template v-else>
                    <b>N° Cod.:</b>{{dataPaciente.cod_asegurado}}
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-5">
                <button
                  type="submit"
                  class="btn bg-custom-green mr-2 rounded"
                  :disabled="buttonDisable"
                  @click="update"
                >
                  Guardar
                </button>
                <router-link :to="{ name: 'internacion-index' }">
                  <button
                    class="btn btn-white border border-light rounded">
                    Regresar
                  </button>
                </router-link>
              </div>
              <div class="col-sm-7 text-right">
                <div class="row">
                  <div class="col">
                    <label class="col-form-label">Los medicamentos se proporcionarán de Farmacia:</label>
                    <button
                    class="ml-2 px-2 btn "
                    :class="medicationsProvidesByPharmacy === false ? 'btn-danger':'btn-secondary'"
                    @click="medicationsProvidesByPharmacy = false"
                  >
                    Rechazado
                  </button>
                  <button
                    class="ml-2 px-2 btn btn-success"
                    :class="medicationsProvidesByPharmacy === true ? 'bg-abierto':'btn-success'"
                    @click="openModalAutorizacionInternacion"
                  >
                    Confirmado
                  </button>
                    <label  v-if="medicationsProvidesByPharmacy === true" class="col-form-label ml-2 px-2 ">Imprimir</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-6">
                <TheModalPreCierre
                  ref="theModalPreCierre"
                  :servicios_internos="serviciosFiltered"
                  :cxp="cuentas_x_pagar"
                  :salidProducto="salidaProductos.detalles"
                  :paciente="dataPaciente"
                  :preCierreData="preCierreData"
                  :editMode="true"
                  :moneda="moneda"
                  @agregarPrecierre="agregarPrecierre"
                  @update:estado_precierre="preCierre=$event"
                  v-on:limpiarPreCierre="limpiarPreCierre"
                  @agregarCierre="agregarCierre"
                  :serviciosComplementarios="servicioComplementario"
                  :totalImporteSalaCama="totalImporteSalaCama"
                />
                <ModalCierre
                  :preCierre="preCierre"
                  :preCierreData="preCierreData"
                  :cierreData="cierre"
                  @agregarCierre="agregarCierre"
                />
                <button
                class="ml-2 px-2 btn btn-secondary "
                @click="goToHistorialClinico"
              >
                H.C. Internacion
              </button>
              <button
              class="ml-2 px-2 btn btn-secondary "
            >
              Signos Vitales
            </button>
            <button
            class="ml-2 px-2 btn btn-secondary "
          >
            Historia Signos Vitales
          </button>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-12">
                    <b-card class="mb-3">
                      <div class="row mb-3">
                        <div class="col-sm-2 text-right pr-0">
                          <label class="col-form-label">
                            Fecha:
                          </label>
                        </div>
                        <div class="col-sm-4 px-0">
                          <date-picker
                            :disabled="true"
                            class="col-sm-10"
                            v-model="fecha"
                            lang="es"
                            format="DD/MM/YYYY"
                            type="date"
                            value-type="date"
                          ></date-picker>
                        </div>
                        <div class="col-sm-2 text-right pr-0">
                          <label class="col-form-label">
                            Fecha Cierre:
                          </label>
                        </div>
                        <div class="col-sm-4 px-0">
                          <date-picker
                            class="col-sm-10"
                            v-model="fechaCierre"
                            lang="es"
                            format="DD/MM/YYYY"
                            type="date"
                            value-type="date"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-sm-2 text-right pr-0">
                          <label class="col-form-label">
                            Paciente:
                          </label>
                        </div>
                        <ComboPaciente
                          id="comboPaciente"
                          :paciente="pacienteSelected"
                          :edit="edit"
                          v-on:addPaciente="AddPaciente"
                          :havePreCierre="preCierre"
                        />
                        <!-- <div class="col-sm-2 pl-0 pr-2" v-if="pacienteSelected">
                          <input
                            id="hc"
                            type="text"
                            class="form-control"
                            :value="'H.C: '+dataPaciente.numero"
                            disabled
                          >
                        </div> -->
                        <div class="col-sm-1 px-0">
                          <button
                            class="btn btn-success col-sm-12 px-0"
                            @click="openModalPaciente"
                          >
                            <i class="i-Business-ManWoman h5"></i>
                          </button>
                          <ModalPacienteNuevo />
                        </div>
                      </div>
                    </b-card>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-sm-12">
                    <b-card class="border border-light">
                      <b>Diagnóstico: </b><span class="text-danger">(Obligatorio)</span>
                      <div class="row mt-1 mb-2">
                        <ComboDiagnostic
                        :id="'comboDiagnostic'"
                        :diagnosticos="listaClasificacionCIEFiltrada"
                        :diagnostico-inicial="diagnosticoSeleccionado"
                        @search="filtrarDiagnosticos"
                        @addDiagnostico="guardarDiagnosticoSeleccionado"
                      />
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <input
                            v-model="diagnostico"
                            type="text"
                            class="form-control"
                          >
                        </div>
                      </div>
                    </b-card>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <b-card class="border border-light">
                      <b class="text-light">Solicitar Cirugia:</b>
                      <div class="row mx-0 pt-1">
                        <QuirofanoItem v-for="(item, index) in cirugias" :key="index"
                          :indexCirugia="index+1"
                          @click.native="openModalCirugia(false)"
                        />
                        <QuirofanoItem
                          :modeAddCirugia="true"
                          @click.native="openModalCirugia(true)"
                        />
                      </div>
                      <TheModalCirugia
                        v-model="modeAddItem"
                        @addNewItem="addItemCirugia($event)"
                        @onChangeData="saveData($event)"
                      />
                    </b-card>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-12">
                    <b-card class="mb-4">
                      <div class="row mb-3">
                        <div class="col-sm-3 px-0 ml-2 text-right">
                          <label class="col-form-label text-skyblue font-weight-bold">
                            {{labelTratamiento}}:
                          </label>
                        </div>
                        <div class="col-sm-8">
                          <multiselect2
                            v-model="categoriaTratamiento.model"
                            selectLabel=""
                            selectedLabel=""
                            deselect-label="X"
                            track-by="nombre"
                            label="nombre"
                            multiple
                            placeholder="Seleccione un item"
                            :options="categoriaTratamiento.listaCategorias"
                            :allow-empty="false"
                            :searchable="true">
                          </multiselect2>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-sm-3 px-0 ml-2 text-right">
                          <label class="col-form-label text-skyblue font-weight-bold">
                            Medico Principal:
                          </label>
                        </div>
                        <div class="col-sm-8">
                          <multiselect2
                            v-model="medico.model"
                            selectLabel=""
                            selectedLabel=""
                            deselect-label="X"
                            track-by="nombre"
                            label="nombre"
                            placeholder="Seleccione un medico"
                            :options="medico.listaMedicos"
                            multiple
                            :searchable="true">
                          </multiselect2>
                        </div>
                      </div>
                      <div class="row mb-3" v-if="isDisableSeguro">
                        <div class="col-sm-2 text-right pr-0">
                          <label class="col-form-label text-light">
                            Seguro:
                          </label>
                        </div>
                        <div class="col-sm-7">
                          <multiselect2
                            v-model="seguro.model"
                            selectLabel=""
                            selectedLabel=""
                            deselect-label="X"
                            track-by="nombre_comercial"
                            label="nombre_comercial"
                            placeholder="Seleccione un seguro"
                            :options="seguro.listaSeguros"
                            :searchable="true"
                          >
                          </multiselect2>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-sm-2 pr-1">
                          <button
                            class="btn col-sm-12"
                            :class="getIDItemChecked !== null? 'btn-success':'btn-warning'"
                            @click="openModalPieza"
                            :disabled="validarButton()"
                          >
                            Pieza
                          </button>
                          <ModalPieza :editMode="true"/>
                        </div>
                        <div class="col-sm-3 pr-1">
                          <input
                            :value="getCamaItemChecked"
                            type="text"
                            class="form-control text-center px-1"
                            disabled
                          >
                        </div>
                        <div class="col-sm-4 pl-1">
                          <input
                            :value="getPisoSalaItemChecked"
                            type="text"
                            class="form-control text-center px-1"
                            disabled
                          >
                        </div>
                        <div class="col-sm-2" v-if="getPisoSalaItemChecked"
                          style="margin: auto;">
                          <span>{{ diasTratamiento }}</span> Dias
                        </div>
                      </div>
                    </b-card>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12">
                    <b-card class="border border-light">
                      <b class="text-light">Inter Consulta:</b>
                      <div class="row mx-0 pt-1">
                        <div
                          @click="openModalInterConsulta"
                          class="cursor-pointer col-sm-2 px-0 mr-3 my-2 border rounded"
                          style="background: #eee;height: 90px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            user-select: none;"
                        >
                          <b
                            style="color:#858189;"
                          >
                            Inter 1
                          </b>
                        </div>
                        <div
                          @click="openModalInterConsulta"
                          class="cursor-pointer col-sm-2 px-0 mr-3 my-2 border rounded"
                          style="height: 90px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            user-select: none;"
                        >
                          <span
                            class="h1 pt-2"
                            style="color:#858189;"
                          >+</span>
                        </div>
                      </div>
                      <TheModalInterConsulta />
                    </b-card>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-9 pl-4" style="display: inline;">
                <b><font size=3>Servicios Complementarios</font></b>
              </div>
              <div class="col-sm-3">
                <div class="form-group row">
                  <div class="col-sm-12">
                    <multiselect2
                      v-model="almacen.model"
                      selectLabel=""
                      selectedLabel=""
                      deselect-label="X"
                      track-by="almacen"
                      label="almacen"
                      placeholder="Seleccione Almacen"
                      :options="almacen.listaAlmacenes"
                      :disabled="isDisabledAlmacen"
                      :allow-empty="false"
                      :searchable="true">
                    </multiselect2>
                  </div>
                </div>
              </div>
            </div>
            <!-- servicios complementarios buttons -->
            <div class="row mb-2">
              <div class="col-sm-12" style="background: #f4b375;">
                <div class="form-group row">
                  <div class="col-sm-12 pt-2">
                    <the-modal-consumo-productos
                      :isActiveProductos="isActiveFarmacia"
                      :almacen="almacen.model"
                      :moneda="moneda.model"
                      :fecha="fecha"
                      :sugerencia_glosas="sugerencias_glosas_sp"
                      :detalles="productosEdit"
                      :havePreCierre="preCierre"
                      :activateButton="nombreButtonActivo == 'productos' ? true : false"
                      v-on:addProducto="addProducto"
                      v-on:activateTheButton="activateTheButton"
                      :nameButton="getNameFarmacia"
                      ref="theModalConsumoProducto"
                      :hasActiveEvolucionMedica="hasActiveEvolucionMedica"
                      />
                    <button
                      v-if="isActiveLaboratorio"
                      class="btn btn-sm text-white mr-2"
                      :id="'popover-target-2'"
                      type="button"
                      :class="getClassButtonLaboratorio"
                      :disabled="preCierre"
                      @click="openModalServicios(getNameLaboratorio, categoriaLaboratorio)"
                    >
                      {{getNameLaboratorio}}
                    </button>
                    <button
                      v-if="isActiveEcografia"
                      class="btn btn-sm text-white mr-2"
                      :id="'popover-target-3'"
                      type="button"
                      :class="getClassButtonEcografia"
                      :disabled="preCierre"
                      @click="openModalServicios(getNameEcografia, categoriaEcografia)"
                    >
                      {{getNameEcografia}}
                    </button>
                    <button
                      v-if="isActiveRayosX"
                      class="btn btn-sm text-white mr-2"
                      :id="'popover-target-4'"
                      type="button"
                      :class="getClassButtonRayosX"
                      :disabled="preCierre"
                      @click="openModalServicios(getNameRayosX, categoriaRayosX)"
                    >
                      {{getNameRayosX}}
                    </button>
                    <button
                      v-if="isActiveTomografia"
                      class="btn btn-sm text-white mr-2"
                      :id="'popover-target-5'"
                      type="button"
                      :class="getClassButtonTomografia"
                      :disabled="preCierre"
                      @click="openModalServicios(getNameTomografia, categoriaTomografia)"
                    >
                      {{getNameTomografia}}
                    </button>
                    <button
                      v-if="isActiveMamografia"
                      class="btn btn-sm text-white mr-2"
                      :id="'popover-target-5'"
                      type="button"
                      :class="getClassButtonMamografia"
                      :disabled="preCierre"
                      @click="openModalServicios(getNameMamografia, categoriaMamografia)"
                    >
                      {{getNameMamografia}}
                    </button>
                    <button
                      v-if="isActiveArcoC"
                      class="btn btn-sm text-white mr-2"
                      :class="getClassButtonArcoC"
                      :disabled="preCierre"
                      @click="openModalServicios(getNameArcoEnC, categoriaArcoC)"
                    >
                      {{getNameArcoEnC}}
                    </button>
                    <button
                      v-if="isActiveFisioterapia"
                      class="btn btn-sm text-white mr-2"
                      :class="getClassButtonFisioterapia"
                      :disabled="preCierre"
                      @click="openModalFisioterapia(getNameFisioterapia, categoriaFisioterapia)"
                    >
                      {{getNameFisioterapia}}
                    </button>
                    <button
                      v-if="isActiveTratamiento"
                      class="btn btn-sm text-white mr-2"
                      :class="getClassButtonTratamiento"
                      :disabled="preCierre"
                      @click="openModalFisioterapia(getNameTratamiento, categoriaTratam)"
                    >
                      {{getNameTratamiento}}
                    </button>
                    <button
                      v-if="hasActiveEvolucionMedica"
                      type="button"
                      class="btn-sm text-white mr-2"
                      :class="nombreButtonActivo == 'evolucion_medica' ?
                        'btn btn-success': 'btn btn-secondary'"
                      @click="onClickButtonEvolucionMedica"
                    >
                      Evolución Médica
                    </button>
                    <the-modal-servicio-externo
                      v-if="hasActiveServicioExt"
                      :data="dataModalCXP"
                      v-on:addCuentaPorPagar="editAddCuentaPorPagar"
                      :havePreCierre="preCierre"
                      :activateButton="nombreButtonActivo == 'servicio_externo' ? true : false"
                      v-on:activateTheButton="activateTheButton"
                    />
                    <TheModalServicioInterno
                      v-if="hasActiveServicioInt"
                      ref="servicioInterno"
                      @addServiciosInternos="addServiciosInternos"
                      :empresa_moneda="empresa_moneda"
                      :sugerencia_glosas="sugerencias_glosas"
                      :medic="medico.listaMedicos"
                      :servicios_internos="serviciosAllInternos"
                      :havePreCierre="preCierre"
                      :activateButton="nombreButtonActivo == 'servicio_interno' ? true : false"
                      @activateTheButton="activateTheButton"
                    />
                    <TheModalEvolucionMedica
                      @addEvolucionMedica="addEvolucionMedica"/>
                  </div>
                </div>
              </div>
            </div>

            <!-- tabla consumo productos -->
            <div class="row mb-2" v-show="nombreButtonActivo=='productos'">
              <div class="col-sm-12 px-0">
                <table class="table table-striped mb-0 table-hover">
                  <thead>
                    <tr>
                      <th width="2%"  class="th-custom-color text-center">N°</th>
                      <th width="10%" class="th-custom-color text-center">Cantidad</th>
                      <th width="40%" class="th-custom-color text-center">Nombre</th>
                      <th width="10%" class="th-custom-color text-center">Importe</th>
                      <th width="20%" class="th-custom-color text-center">Concentrado</th>
                      <th width="10%" class="th-custom-color text-center">Via</th>
                      <th width="15%" class="th-custom-color text-center">Indicacion</th>
                      <th width="3%" class="th-custom-color text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="filterListaTemporalConsumoProducto.length === 0">
                      <td colspan="7" class="text-center">
                        No hay consumo productos registrados
                      </td>
                    </tr>
                    <tr v-for="(item, index) in filterListaTemporalConsumoProducto" :key="index">
                      <td class="text-center">{{item.numero}}
                        <div v-if="item.tipo_consumo !== 3" class="custom-small-text">{{formattedDate(item.fecha)}}</div>
                        <div v-else class="custom-small-text">{{formattedDate(item.fecha_hora)}}</div>
                      </td>
                      <td class="text-center">
                        <button
                        :class="{
                          'btn btnOrange': item.tipo_consumo === 1,
                          'btn btnTurquesa': item.tipo_consumo === 2,
                          'btn btnBlue': item.tipo_consumo === 3
                        }"
                        >
                          {{item.cantidad}}
                        </button>
                      </td>
                      <td class="text-center">{{item.producto_nombre}}</td>
                      <div v-if="item.tipo_consumo !== 3">
                        <td class="text-center">{{ parseFloat(item.importe).toFixed(2) }}</td>
                      </div>
                      <div v-else>
                        <td class="text-center"></td>
                      </div>
                      <td class="text-center"></td>
                      <td class="text-center">{{item.via}}</td>
                      <td class="text-center">{{item.indicacion}}</td>
                      <td class="text-center">
                        <i
                          v-if="!preCierre"
                          class="nav-icon i-Close-Window text-danger font-weight-bold"
                          style="cursor: pointer"
                          @click="eliminarProducto(item)"
                        ></i>
                        <i v-else
                          class="nav-icon i-Close-Window font-weight-bold"
                          style="cursor: pointer"
                          :title="messagePreCierre"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="filterListaTemporalConsumoProducto.length > 0">
                  <div class="row  text-right">
                    <div class="col-sm-1 ml-auto th-custom-color">
                      <h4><strong>Total:</strong></h4>
                    </div>
                    <div class="col-sm-1 mr-3 th-custom-color">
                      <div class="div-input bg-main">{{this.totalImporte.toFixed(2)}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- tabla servicio internos -->
            <div class="row mb-2" v-show="nombreButtonActivo=='servicio_interno'">
              <div class="col-sm-12 rounded th-custom-color text-black py-2">
                <div class="row">
                  <div class="col-sm-1 font-weight-bold"> &nbsp;&nbsp;</div>
                  <div class="col-sm-3 font-weight-bold">Fecha</div>
                  <div class="col-sm-6 font-weight-bold">Servicios</div>
                  <div class="col-sm-2 font-weight-bold text-center">Hora</div>
                </div>
              </div>
              <div class="col-sm-12"
                :class="serviciosFiltered.length > 2? 'tabla-scroll-vertical': ''">
                <template v-if="serviciosFiltered.length > 0">
                  <div
                    class="row mb-2 border-light pt-2"
                    v-for="(item, index) in serviciosFiltered" :key="item.id"
                    :class="index === 0 ? '': 'border-top'"
                  >
                    <div class="col-sm-1">
                      <!-- <i
                        class="nav-icon i-Pen-5 text-success font-weight-bold"
                        style="cursor: pointer"
                        @click="showModalServicioInterno(item)"
                      >
                      </i> -->
                      <i class="nav-icon i-Close-Window text-danger font-weight-bold"
                        style="cursor: pointer"
                        v-if="!preCierre"
                        @click="eliminarServiciosInternos(item)"></i>
                      <i v-else
                        class="nav-icon i-Close-Window font-weight-bold"
                        style="cursor: pointer"
                        :title="messagePreCierre"
                      ></i>
                    </div>
                    <div class="col-sm-3">
                      {{item.venta_servicio_fecha}}
                      <br>
                      <b>User: </b> {{ item.usuario }}
                    </div>
                    <div class="col-sm-6">
                      {{item.tipo_servicio_nombre}}
                      <br>
                      <b>Glosa: </b> {{ item.textoglosa }}
                    </div>
                    <div class="col-sm-2 text-center">
                      {{item.hora}}
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="row mb-2 border-light pt-2 border-top">
                    <div class="col-sm-12 text-center">
                      No hay servicios internos registrados
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <!-- servicios externos (CXP)-->
            <div class="row mb-2" v-show="nombreButtonActivo=='servicio_externo'">
              <div class="col-sm-12 rounded th-custom-color text-black py-2">
                <div class="row">
                  <div class="col-sm-1 font-weight-bold"> &nbsp;&nbsp;</div>
                  <div class="col-sm-3 p-0 font-weight-bold">Fecha</div>
                  <div class="col-sm-6 font-weight-bold">Servicios</div>
                  <div class="col-sm-2 font-weight-bold text-center">Hora</div>
                </div>
              </div>
              <div class="col-sm-12"
                :class="cuentas_x_pagar.length > 3? 'tabla-scroll-vertical': ''">
                <template v-if="cuentas_x_pagar.length > 0">
                  <div
                    class="row mb-2 border-light pt-2"
                    v-for="(item, index) in cuentas_x_pagar" :key="''+item.id + index"
                    :class="index === 0 ? '': 'border-top'">
                    <div class="col-sm-1">
                      <the-modal-servicio-externo
                        :data="dataModalCXP"
                        :editCxPIndex="index"
                        :havePreCierre="preCierre"
                        v-on:addCuentaPorPagar="editAddCuentaPorPagar"
                        :activateButton="nombreButtonActivo == 'servicio_externo' ? true : false"
                      /> &nbsp;&nbsp;
                      <i class="nav-icon i-Close-Window text-danger font-weight-bold"
                        v-if="!preCierre"
                        style="cursor: pointer"  @click="eliminarCuentaPorPagar(index)"></i>
                      <i v-else
                        class="nav-icon i-Close-Window font-weight-bold"
                        style="cursor: pointer"
                        :title="messagePreCierre"
                      ></i>
                    </div>
                    <div class="col-sm-3 p-0">
                      {{ getFechaFormat(item.fecha_deuda,'DD/MM/YYYY') }} <br>
                      {{item.usuario}}
                    </div>
                    <div class="col-sm-6 p-0">
                      {{item.origen_type}}: {{item.numero}} - Dr. {{item.medico}} -
                      <strong>Desc.: </strong>
                      {{item.glosa  ? item.glosa : item.descripcion}}
                    </div>
                    <div class="col-sm-2 text-center">
                      {{ getFechaFormat(item.created_at,'HH:mm')}}
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="row mb-2 border-light pt-2 border-top">
                    <div class="col-sm-12 text-center">
                      No hay servicios externos registrados
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <!-- tabla laboratorio, ecografia, rayos x, tomografia, arco c -->
            <div
              class="row mb-2"
              v-if="
              categoriaServicioComplId === categoriaLaboratorio
              || categoriaServicioComplId === categoriaEcografia
              || categoriaServicioComplId === categoriaRayosX
              || categoriaServicioComplId === categoriaTomografia
              || categoriaServicioComplId === categoriaMamografia
              || categoriaServicioComplId === categoriaArcoC
                ">
              <div class="col-sm-12 px-0">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th width="5%" class="th-custom-color text-center">N°</th>
                      <th width="20%" class="th-custom-color text-center">Categoria</th>
                      <th width="10%" class="th-custom-color text-center">Cant.</th>
                      <th width="20%" class="th-custom-color text-center">Servicios</th>
                      <th width="15%" class="th-custom-color text-center">Lista</th>
                      <th width="25%" class="th-custom-color text-center">Descripcion</th>
                      <th width="3%" class="th-custom-color text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(servicio, index) in listTempServiciosComplementarios" :key="index">
                      <td class="text-center">{{ listTempServiciosComplementarios.length - index }} </td>
                      <td class="text-center">{{ servicio.categoria }}</td>
                      <td class="text-center">
                        <button
                        :class="{
                          'btn btnTurquesa': servicio.tipo_consumo_servicio === 1,
                          'btn btnBlue': servicio.tipo_consumo_servicio === 2
                        }"
                        >
                          {{servicio.cantidad}}
                        </button>
                      </td>
                      <td class="text-center">{{ servicio.nombre }}</td>
                      <td class="text-center">{{ servicio.precio }}</td>
                      <td class="text-center">
                        <input type="text"
                          v-model="servicio.descripcion"
                          class="form-control">
                      </td>
                      <td class="text-center">
                        <i
                          v-if="!preCierre"
                          class="nav-icon i-Close-Window text-danger font-weight-bold"
                          style="cursor: pointer"
                          @click="eliminarServicio(servicio)"
                        ></i>
                        <i v-else
                          class="nav-icon i-Close-Window font-weight-bold"
                          style="cursor: pointer"
                          :title="messagePreCierre"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- tabla para fisioterapia -->
            <div class="row mb-2"  v-if="categoriaServicioComplId === categoriaFisioterapia
            || categoriaServicioComplId === categoriaTratam"
            >
              <div class="col-sm-12 px-0">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th width="5%" class="th-custom-color text-center">N°</th>
                      <th width="37%" class="th-custom-color text-center">Servicio</th>
                      <th width="20%" class="th-custom-color text-center">N° de Session</th>
                      <th width="15%" class="th-custom-color text-center">Lista</th>
                      <th width="35%" class="th-custom-color text-center">Descripción</th>
                      <th width="3%" class="th-custom-color text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(servicio, index) in listTempServiciosComplementarios" :key="index">
                      <td class="text-center">{{ listTempServiciosComplementarios.length - index }} </td>
                      <td class="text-center">{{ servicio.categoria }} </td>
                      <td class="text-center">
                        <button
                        :class="{
                          'btn btnTurquesa': servicio.tipo_consumo_servicio === 1,
                          'btn btnBlue': servicio.tipo_consumo_servicio === 2
                        }"
                        >
                          {{servicio.session}}
                        </button>
                      </td>
                      <td class="text-center">{{ servicio.precio }}</td>
                      <td class="text-center">
                        <input type="text"
                          v-model="servicio.descripcion"
                          class="form-control">
                      </td>
                      <td class="text-center">
                        <i
                          v-if="!preCierre"
                          class="nav-icon i-Close-Window text-danger font-weight-bold"
                          style="cursor: pointer"
                          @click="eliminarServicio(servicio)"
                        ></i>
                        <i v-else
                          class="nav-icon i-Close-Window font-weight-bold"
                          style="cursor: pointer"
                          :title="messagePreCierre"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- tabla Evolución Médica -->
            <div class="row mb-2" v-show="nombreButtonActivo === 'evolucion_medica'">
              <div class="col-sm-12 px-0">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr class="th-custom-color ">
                      <th class="py-2 text-center" width="5%">N°</th>
                      <th class="py-2 text-center" width="10%">Fecha</th>
                      <th class="py-2 text-center" width="10%">Hora</th>
                      <th class="py-2 text-center" width="24%">Descripción</th>
                      <th class="py-2 text-center" width="24%">Indicación</th>
                      <th class="py-2 text-center" width="24%">Médico</th>
                      <th width="3%" class="th-custom-color text-center"></th>
                    </tr>
                  </thead>
                  <tbody class="text-center">
                    <tr v-for="(evolucion, index) in evolucionFiltered" :key="index">
                      <td class="text-center">{{ listTempServiciosComplementarios.length - index }} </td>
                      <td class="text-center"> {{ evolucion.fecha }} </td>
                      <td class="text-center"> {{ evolucion.hora }} </td>
                      <td class="text-center"> {{ evolucion.descripcion_medico }} </td>
                      <td class="text-center"> {{ evolucion.indicacion_medico }} </td>
                      <td class="text-center"> {{ evolucion.medico }} </td>
                      <td class="text-center">
                        <i
                          class="nav-icon i-Close-Window font-weight-bold text-danger"
                          style="cursor: pointer"
                          @click="eliminarEvolucionMedica(evolucion)"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <TheModalAutorizacionInternacion/>
    <TheModalServicios
      :titleModal="nombreButtonActivo"
      :isInternation="isInternation"
      @addServiciosComplemetarios="addServiciosComplemetarios"
      @setTipoConsumoProducto="setTipoConsumoProducto"
      :seguro="seguro.model"
      :moneda="moneda"
      :hasActiveEvolucionMedica="hasActiveEvolucionMedica"
    />
    <TheModalFisioterapia
      :titleModal="nombreButtonActivo"
      :isInternation="isInternation"
      @addServiciosComplemetarios="addServiciosComplemetarios"
      @setTipoConsumoProducto="setTipoConsumoProducto"
      :seguro="seguro.model"
      :moneda="moneda"
      :hasActiveEvolucionMedica="hasActiveEvolucionMedica"
    />
  </div>
</template>

<script>
import store from '@/store/index';
import Multiselect2 from '@/components/util/vue-multiselect2';
import DatePicker from 'vue2-datepicker';
import axios from '@/modules/common/axiosERP';
import moment from 'moment';
import { util } from '@/plugins/util';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { CATEGORIA_SERVICIO_COMPL, TIPO_CONSUMO_PRODUCTO_SERVICIO } from '@/utils/globalConventions';
import ComboPaciente from '../components/ThePatient.vue';
import ModalPacienteNuevo from '../components/TheModalPacienteNuevo.vue';
import ModalPieza from '../components/TheModalPieza.vue';
import TheModalServicioExterno from '../components/TheModalServicioExterno.vue';
import TheModalConsumoProductos from '../components/TheModalConsumoProductos.vue';
import TheModalServicioInterno from '../components/TheModalServicioInterno.vue';
import TheModalPreCierre from '../components/TheModalPreCierre.vue';
import ModalCierre from '../components/TheModalCierre.vue';
import TheModalServicios from '../../asistenciaMedico/components/TheModalServicios.vue';
import TheModalFisioterapia from '../../asistenciaMedico/components/TheModalFisioterapia.vue';
import QuirofanoItem from '../components/QuirofanoItem.vue';
import TheModalCirugia from '../components/TheModalCirugia.vue';
import TheModalEvolucionMedica from '../components/TheModalEvolucionMedica.vue';
import TheModalInterConsulta from '../components/TheModalInterConsulta.vue';
import TheModalAutorizacionInternacion from '../components/TheModalAutorizacionInternacion.vue';
import ComboDiagnostic from '../components/TheDiagnostic.vue';

export default {
  name: 'edit',
  components: {
    Multiselect2,
    DatePicker,
    ComboPaciente,
    ModalPacienteNuevo,
    ModalPieza,
    TheModalServicioExterno,
    TheModalConsumoProductos,
    TheModalServicioInterno,
    TheModalPreCierre,
    ModalCierre,
    TheModalServicios,
    TheModalFisioterapia,
    QuirofanoItem,
    TheModalCirugia,
    TheModalEvolucionMedica,
    TheModalInterConsulta,
    TheModalAutorizacionInternacion,
    ComboDiagnostic,
  },
  data() {
    return {
      id: atob(this.$route.params.id),
      isLoading: true,
      fecha: null,
      pacienteSelected: null,
      pacientes: [],
      servicios: [],
      isInternation: true,
      fechaCierre: new Date(),
      categoriaTratamiento: {
        listaCategorias: [],
        model: null,
      },
      medico: {
        listaMedicos: [],
        model: [],
      },
      seguro: {
        listaSeguros: [],
        model: null,
      },
      almacen: {
        listaAlmacenes: [],
        model: null,
      },
      numero: '',
      edit: true,
      dataPaciente: {},
      edad: '',
      mes: '',

      // CXP
      moneda: {
        model: null,
        multiselect: [],
        nuevo_tipo_cambio: null,
        tc_secundario: null,
      },
      cxp: {},
      dataModalCXP: {},
      cuentas_x_pagar: [],
      cuentas_x_pagar_origin: [],

      // Consumo Productos SP
      consumoProductos: [],
      salidaProductos: {
        total: 0,
        detalles: [],
      },
      sugerencias_glosas_sp: [],
      productosEdit: [],

      sugerencias_glosas: [],
      servicios_origin: [],
      serviciosAllInternos: [],
      // anticipo: 0,
      preCierre: false,
      categoriaServiciosInternos: [],
      preCierreData: {
        id: null,
        total_precierre: 0,
        saldo_precierre: 0,
        anticipo_precierre: 0,
        glosa_precierre: null,
        estado_precierre: false,
        custom_id: null,
        fecha: null,
        hora: null,
        usuario: null,
      },
      cierre: {
        fecha: null,
        hora: null,
        glosa: null,
        estado: false,
      },
      empresa_moneda: null,
      messagePreCierre: 'Acción no permita usted tiene un precierre',
      isDisableSeguro: store.getters['main/hasPermisionListarSeguro'],
      buttonDisable: false,
      modeAddItem: false,
      cirugias: [
        {
          id: 1,
          modeAddCirugia: false,
        },
      ],
      nombreButtonActivo: '',
      labelTratamiento: 'label',
      listaClasificacionCIE: [],
      clasificacionCIE: null,
      categoriaServicioComplId: null,
      servicioComplementario: [],
      diagnostico: '',
      medicationsProvidesByPharmacy: null, // false es rechazado y true es confirmado, null es no selecciono nada
      parametroServicioComplementarioList: [],
      tipo_consumo_producto: null,
      listTempServiciosComplementarios: [],
      evolucionMedicas: [],
      hasActiveEvolucionMedica: false,
      hasActiveServicioExt: false,
      hasActiveServicioInt: false,
      listaClasificacionCIEFiltrada: [],
      diagnosticoSeleccionado: null, // Inicialmente null
      diasTratamiento: 0,
      datoPieza: null,
    };
  },
  async created() {
    this.cargarDatosIniciales();
    await this.getParametrosConsultaExterna();
    this.detectAndSetComponentActive();
  },
  beforeDestroy() {
    this.resetModalPieza();
  },
  methods: {
    // eslint-disable-next-line consistent-return
    validarButton() {
      return this.datoPieza !== null;
    },
    async cargarDatosIniciales() {
      this.isLoading = true;
      try {
        const RESPONSE = await axios.get(`clinic/tratamientos/${this.id}/edit`);
        const DATA_TRATAMIENTOS = RESPONSE.data.data.data_tratamientos;
        const nombreParametroTratamiento = RESPONSE.data.data.nombre_parametro_consulta_externa;
        this.labelTratamiento = nombreParametroTratamiento?.nombre_tratamiento_principal ?? '';
        this.categoriaTratamiento.listaCategorias = RESPONSE.data.data.categoria_tratamientos;
        this.medico.listaMedicos = RESPONSE.data.data.medicos;
        this.seguro.listaSeguros = RESPONSE.data.data.seguros;
        this.almacen.listaAlmacenes = RESPONSE.data.data.almacens;
        this.fecha = new Date(moment(DATA_TRATAMIENTOS.fecha));
        this.fechaCierre = new Date(moment(DATA_TRATAMIENTOS.fecha_cierre));
        this.numero = DATA_TRATAMIENTOS.numero;
        this.pacienteSelected = RESPONSE.data.data.paciente;
        this.dataPaciente = RESPONSE.data.data.paciente;
        this.edad = this.dataPaciente.edad;
        this.mes = this.dataPaciente.meses;
        this.medico.model = RESPONSE.data.data.tratamiento_medicos;
        this.seguro.model = this.seguro.listaSeguros.find((ele) => (
          ele.id === DATA_TRATAMIENTOS.seguro_id));
        this.almacen.model = this.almacen.listaAlmacenes.find((ele) => (
          ele.id === DATA_TRATAMIENTOS.almacen_id));
        this.categoriaTratamiento.model = RESPONSE.data.data.detalle_categorias_tratamientos;
        this.cargarModalPieza(RESPONSE.data.data.sala_camas);
        this.cargarSalaCamaID(DATA_TRATAMIENTOS.sala_cama_id);
        this.datoPieza = DATA_TRATAMIENTOS.sala_cama_id;
        // carga datos Modal cxp
        this.moneda.multiselect = RESPONSE.data.data.monedas;
        this.moneda.model = this.loadMonedaPrincipal(this.moneda.multiselect);
        // this.dataModalCXP.medicos = this.medico.listaMedicos;
        this.dataModalCXP.medicos = RESPONSE.data.data.medicos;
        this.dataModalCXP.monedas = this.moneda;
        this.dataModalCXP.parametrosContables = RESPONSE.data.data.pametrosContables;
        this.dataModalCXP.tipo_comprobantes = RESPONSE.data.data.tipo_comprobante
          .filter((ele) => (ele.id === 1 || ele.id === 4));
        this.dataModalCXP.cuenta_credito_fiscal = RESPONSE.data.data.cuenta_credito_fiscal;
        this.cuentas_x_pagar = RESPONSE.data.data.cuentas_x_pagar;
        this.cuentas_x_pagar.forEach((item, index) => {
          this.cuentas_x_pagar[index].importe_neto = item.importe_s_d - item.descuento;
          this.cuentas_x_pagar[index].importe = item.importe_s_d;
        });
        this.cuentas_x_pagar_origin = this.cloneObject(RESPONSE.data.data.cuentas_x_pagar);
        this.dataModalCXP.tipoFacturas = RESPONSE.data.data.tipo_facturas;
        this.dataModalCXP.cuentas_x_pagar = this.cuentas_x_pagar;

        // Consumo Productos SP
        this.consumoProductos = RESPONSE.data.data.consumo_productos;
        this.sugerencias_glosas_sp = RESPONSE.data.data.sugerencia_glosas_consumo_productos;
        if (this.consumoProductos.length > 0) {
          this.cargarConsumoProductos();
          this.productosEdit = this.cloneObject(this.consumoProductos);
        }
        this.servicioComplementario = RESPONSE.data.data.servicios_complementarios;
        this.servicioComplementario.forEach((el, index) => {
          this.servicioComplementario[index].estado = el.estado === 1;
        });
        this.servicios = RESPONSE.data.data.tratamiento_venta_servicios;
        this.sugerencias_glosas = RESPONSE.data.data.sugerencia_glosas;
        this.serviciosAllInternos = RESPONSE.data.data.tipo_servicios;
        this.categoriaServiciosInternos = RESPONSE.data.data.categoria_medico;
        this.cargarModalPreCierre(RESPONSE.data.data.preCierre);
        this.setServicesData();
        this.empresa_moneda = RESPONSE.data.data.empresa_moneda;
        this.listaClasificacionCIE = RESPONSE.data.data.clasificacion_cies;
        this.clasificacionCIE = this.listaClasificacionCIE.find((el) => (
          el.id === DATA_TRATAMIENTOS.clasificacion_cie1_id
        ));
        this.clasificacionObtenida(DATA_TRATAMIENTOS);
        this.diagnostico = DATA_TRATAMIENTOS.diagnostico1;
        this.evolucionMedicas = RESPONSE.data.data.evolucion_medica;
        this.hasActiveEvolucionMedica = RESPONSE.data.data.has_active_evolucion_medica;
        this.hasActiveServicioExt = RESPONSE.data.data.has_active_servicio_ext;
        this.hasActiveServicioInt = RESPONSE.data.data.has_active_servicio_int;
        this.diasTratamiento = RESPONSE.data.data.dias_tratamiento;
      } catch (error) {
        console.log('error', error);
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async getParametrosConsultaExterna() {
      try {
        const response = await axios.get('/clinic/servicio_complementario/nombre_parametro_servicio_consulta/create');
        const parametro2 = response.data.data.nombre_parametro_servicio_complementario;
        if (parametro2.length !== 0) {
          this.parametroServicioComplementarioList = parametro2;
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    detectAndSetComponentActive() {
      if (this.isActiveFarmacia) {
        this.nombreButtonActivo = 'productos';
        return;
      }
      if (this.isActiveLaboratorio) {
        this.nombreButtonActivo = 'Laboratorio';
        return;
      }
      if (this.isActiveEcografia) {
        this.nombreButtonActivo = 'Ecografia';
        return;
      }
      if (this.isActiveRayosX) {
        this.nombreButtonActivo = 'Rayos_X';
        return;
      }
      if (this.isActiveTomografia) {
        this.nombreButtonActivo = 'Tomografia';
        return;
      }
      if (this.isActiveMamografia) {
        this.nombreButtonActivo = 'Mamografia';
        return;
      }
      if (this.isActiveArcoC) {
        this.nombreButtonActivo = 'arco_c';
        return;
      }
      if (this.isActiveFisioterapia) {
        this.nombreButtonActivo = 'fisioterapia';
        this.nombreButtonActivo = 'Fisioterapia';
        return;
      }
      if (this.isActiveTratamiento) {
        this.nombreButtonActivo = 'Tratamiento';
        return;
      }
      this.nombreButtonActivo = '';
    },
    openModalPaciente() {
      this.$bvModal.show('modal-pacienteNuevo');
    },
    formatearDate(fecha) {
      const dat = new Date(fecha);
      return new Date(dat.setDate(dat.getDate() + 1));
    },
    AddPaciente(paciente) {
      if (paciente) {
        // this.$refs.theModalPreCierre.restaurar();
        this.preCierre = false;
        this.dataPaciente = paciente.datosPaciente;
        this.edad = (paciente.datosPaciente.edad) ? `${paciente.datosPaciente.edad} años` : '0 años';
        this.mes = (paciente.datosPaciente.meses) ? `${paciente.datosPaciente.meses} mes` : '0 mes';
        // this.showCortinaInformativa = true;
        this.pacienteSelected = paciente.pacienteSelect;
        this.preCierreData.anticipo_precierre = (this.dataPaciente.anticipo)
          ? this.dataPaciente.anticipo : 0;
      } else {
        this.edad = '';
        this.mes = '';
        // this.showCortinaInformativa = false;
        this.pacienteSelected = '';
        this.dataPaciente = '';
      }
    },
    update() {
      if (this.validar()) {
        this.isLoading = true;
        this.preCierreData.estado_precierre = this.preCierre;
        this.cargarDatosPreCierre();
        const DATA = {
          paciente_id: this.pacienteSelected.id,
          fecha: moment(this.fecha).format('YYYY-MM-DD'),
          fecha_cierre: moment(this.fechaCierre).format('YYYY-MM-DD'),
          almacen_id: (this.almacen.model) ? this.almacen.model.id : null,
          seguro_id: (this.seguro.model) ? this.seguro.model.id : '',
          tratamiento_medicos: this.medico.model,
          categoria_tratamientos: this.categoriaTratamiento.model,
          monedas: this.moneda.multiselect,
          cuentas_x_pagar: this.cuentasPorPagarSendData(),
          sala_cama_id: this.getIDItemChecked,
          venta_servicios: this.clearParams(),
          consumo_productos: this.salidaProductosSendData(),
          preCierre: this.preCierreData,
          cierre: this.cierre,
          clasificacion_cie_id: this.diagnosticoSeleccionado?.id ?? null,
          subclasificacion_cie_id: this.diagnosticoSeleccionado?.id_sub ?? null,
          nombre_diagnostico: this.diagnostico,
          detalles_cirugias: [],
          servicios_complementarios: this.servicioComplementario,
          evolucion_medica: this.evolucionMedicas,
          dias_tratamiento: this.diasTratamiento,
          estado_cama_id: 1,
        };
        this.buttonDisable = true;
        axios.put(`clinic/tratamientos/update/${this.id}`, DATA)
          .then((response) => {
            util.showNotify(response.data.message, 'success');
            this.$router.push('/internacion');
          })
          .catch((error) => {
            const FALLO_VALIDACION = 422;
            if (error.response.status !== FALLO_VALIDACION) {
              util.showNotify(error.response.data.message, 'error');
            } else {
              Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                mensajes.forEach((texto) => util.showNotify(texto, 'error'));
              });
            }
          })
          .finally(() => {
            this.buttonDisable = false;
            this.isLoading = false;
          });
      }
    },
    clearParams() {
      this.servicios.forEach((e, index) => {
        delete this.servicios[index].customId;
        delete this.servicios[index].usuario;
        delete this.servicios[index].textoglosa;
        delete this.servicios[index].tipo_servicio_nombre;
      });
      return this.servicios;
    },
    validar() {
      let validate = true;
      if (!this.pacienteSelected) {
        util.showNotify('Seleccione un paciente', 'warn');
        validate = false;
      }
      if (!this.fecha) {
        util.showNotify('La fecha es un campo obligatorio', 'warn');
        validate = false;
      }
      if (!this.fechaCierre) {
        util.showNotify('La fecha de cierre es un campo obligatorio', 'warn');
        validate = false;
      }
      if ((this.consumoProductos.length > 0 || this.cuentas_x_pagar_origin.length > 0)
        && !this.almacen.model) {
        util.showNotify('Debe seleccionar un almacen. \n Si va registrar Consumo Productos o Servicios Externos', 'warn');
        validate = false;
      }
      if (this.medico.model.length === 0) {
        util.showNotify('Selecciones un medico', 'warn');
        validate = false;
      }
      if (!this.categoriaTratamiento.model) {
        util.showNotify('Seleccione un tratamiento', 'warn');
        validate = false;
      }
      if (!this.diagnosticoSeleccionado) {
        util.showNotify('Seleccione un Diagnóstico', 'warn');
        validate = false;
      }
      if (this.fecha && this.fechaCierre) {
        if (this.fecha.getTime() > this.fechaCierre.getTime()) {
          util.showNotify('La fecha de cierre debe ser mayor que la fecha inicio', 'error');
          validate = false;
        }
      }
      // if (!this.preCierre) {
      //   util.showNotify('Debe calcular su pre cierre', 'error');
      //   validate = false;
      // }
      return validate;
    },
    ...mapActions('theModalServicios', [
      'resetDataTheModaServios',
      'getDataServicioComplementario',
    ]),
    addCuentaPorPagar(cuentaPorPagar) {
      const cxpObj = JSON.stringify(cuentaPorPagar);
      this.cxp = JSON.parse(cxpObj);
      this.cuentas_x_pagar.push(this.cxp);
      // this.$refs.theModalPreCierre.restaurar();
    },
    loadMonedaPrincipal(monedas) {
      for (let i = 0; i < monedas.length; i += 1) {
        if (monedas[i].is_principal === 1) {
          return monedas[i];
        }
      }
      return null;
    },
    transformCXPEdit(cxp) {
      const cxpNew = {
        id: (cxp.id) ? cxp.id : 0,
        persona_id: cxp.medico.persona_id,
        medico: cxp.medico.nombre,
        numero: cxp.numero,
        origen_type: cxp.origen_type,
        tipo_comprobante_id: cxp.tipo_comprobante.id,
        comprobante: cxp.t_comprobante_child,
        pre_retencion_tipo_id: (cxp.preRetencionTipo) ? cxp.preRetencionTipo.id : null,
        nuevo_tipo_cambio: cxp.nuevo_tipo_cambio,
        fecha_deuda: cxp.fecha,
        moneda_id: cxp.moneda.id,
        cuenta_contable_id: cxp.cuenta_pasivo_id,
        cuenta_contable_gasto_id: cxp.cuenta_gasto_id,
        descripcion: cxp.glosa,
        importe: cxp.importe,
        retencion: cxp.retencion,
        importe_neto: cxp.importe_neto,
        descuento: cxp.descuento,
        fecha_vencimiento: cxp.fecha_vencimiento,
        factura: (cxp.tipo_comprobante.id === 1) ? cxp.factura : null,
        index: cxp.index,
        usuario: cxp.usuario,
        created_at: cxp.created_at,
        importe_actual: cxp.importe_actual,
      };
      if (cxp.id && cxp.id > 0) {
        cxpNew.editable = true;
      }
      return cxpNew;
    },
    editAddCuentaPorPagar(cuentaPorPagar) {
      const cxpEdit = this.transformCXPEdit(cuentaPorPagar);
      this.cxp = this.cloneObject(cxpEdit);
      this.agregarCuentasPorPagar(this.cxp);
    },
    eliminarCuentaPorPagar(index) {
      const cxpDel = this.cuentas_x_pagar[index];
      const indexReal = this.cuentas_x_pagar_origin.findIndex((ele) => (ele.id === cxpDel.id));
      if (cxpDel.id > 0) {
        delete this.cuentas_x_pagar_origin[indexReal].editable;
        this.cuentas_x_pagar_origin[indexReal].eliminar = true;
      } else {
        this.cuentas_x_pagar_origin.splice(indexReal, 1);
      }
      this.cuentas_x_pagar.splice(index, 1);
    },
    agregarCuentasPorPagar(cxp) {
      if (cxp.index >= 0) {
        // let index = cxp.index;
        this.cuentas_x_pagar.splice(cxp.index, 1, cxp);
        const indexReal = this.cuentas_x_pagar_origin.findIndex((ele) => (ele.id === cxp.id));
        this.cuentas_x_pagar_origin.splice(indexReal, 1, cxp);
      } else {
        this.cuentas_x_pagar.push(this.cxp);
        this.cuentas_x_pagar_origin.push(this.cxp);
      }
    },
    cuentasPorPagarSendData() {
      const cxps = [];
      this.cuentas_x_pagar_origin.forEach((cxp) => {
        cxps.push({
          id: cxp.id,
          persona_id: cxp.persona_id, // 24002,
          t_comprobante_id: cxp.tipo_comprobante_id,
          t_comprobante_child: cxp.comprobante,
          pre_retencion_tipo_id: (cxp.preRetencionTipo) ? cxp.preRetencionTipo.id : null,
          nuevo_tipo_cambio: (cxp.nuevo_tipo_cambio) ? cxp.nuevo_tipo_cambio : null,
          fecha: cxp.fecha_deuda,
          fecha_hora: this.getFechaFormat(cxp.created_at, 'YYYY-MM-DD HH:mm:ss'),
          moneda_id: cxp.moneda_id,
          cuenta_pasivo_id: cxp.cuenta_contable_id,
          cuenta_gasto_id: cxp.cuenta_contable_gasto_id,
          descripcion: cxp.descripcion,
          importe: cxp.importe,
          retencion: cxp.retencion,
          importe_neto: (cxp.importe_neto) ? cxp.importe_neto : cxp.importe,
          descuento: cxp.descuento,
          sucursal_id: (cxp.sucursal_id) ? cxp.sucursal_id : null,
          fecha_vencimiento: cxp.fecha_vencimiento,
          factura: ((cxp.tipo_comprobante_id === 1)) ? cxp.factura : null,
          editar: cxp.editable,
          eliminar: cxp.eliminar,
          tributo_id: cxp.tributo_id ? cxp.tributo_id : null,
          importe_actual: cxp.importe_actual,
        });
      });
      return cxps;
    },
    cloneObject(object) {
      const objectString = JSON.stringify(object);
      return JSON.parse(objectString);
    },
    getFechaFormat(dateString, formato) {
      return moment(dateString).format(formato);
    },
    addProducto(detalles) {
      const productoObj = this.cloneObject(detalles);
      this.salidaProductos.detalles = [];
      this.consumoProductos = [];
      for (let index = 0; index < productoObj.length;) {
        const ele = productoObj[index];
        index += 1;
        let importe = 0;
        if (ele.importe === null || typeof ele.importe === 'undefined') {
          if (ele.tipo_consumo === 1) {
            importe = ele.cantidad * ele.costo_unitario;
          } else if (ele.tipo_consumo === 2) {
            importe = ele.cantidad * ele.producto_precio;
          }
        } else {
          importe = ele.importe;
        }

        this.consumoProductos.push({
          producto_numero: ele.producto_numero,
          codigo: ele.codigo,
          producto_id: ele.producto_id,
          producto_nombre: ele.producto_nombre,
          moneda_id: ele.moneda_id,
          unidad_medida_id: ele.unidad_medida_id,
          unidad_medida_descripcion: ele.unidad_medida_descripcion,
          descripcion: ele.descripcion,
          glosa: ele.glosa,
          cantidad: ele.cantidad,
          fecha: ele.fecha,
          fecha_hora: ele.fecha_hora,
          usuario: ele.usuario,
          tipo_consumo: ele.tipo_consumo,
          estado: ele.estado,
          consumo_producto_id: ele.consumo_producto_id,
          created_at: ele.created_at ? ele.created_at : ele.fecha,
          numero: ele.numero ? ele.numero : 'Local',
          // medicamento: ele.medicamento_nombre,
          indicacion_medicamento_id: ele.indicacion_medicamento_id,
          indicacion: ele.indicacion,
          via: ele.via,
          key: ele.id ? ele.id : ele.key,
          importe: parseFloat(importe).toFixed(2),
        });
        if (ele.tipo_consumo !== 3) {
          const moneda = this.moneda.multiselect.find((mo) => mo.id === ele.moneda_id);
          if (moneda && moneda.is_principal !== 1) {
            // eslint-disable-next-line operator-assignment
            ele.producto_precio = moneda.tc * ele.producto_precio;
          }
          ele.total_venta = ele.producto_precio * ele.cantidad;
        }
        this.salidaProductos.detalles = productoObj;
      }
    },
    eliminarProducto(item) {
      if (item.consumo_producto_id > 0 && item.tipo_consumo === 3) {
        const INDEX = this.consumoProductos.findIndex((el) => el.key === item.key);
        this.consumoProductos[INDEX].estado = false;

        const INDEX_SALIDA_PRODUCTO = this.salidaProductos.detalles.findIndex((el) => el.key === item.key);
        this.salidaProductos.detalles[INDEX_SALIDA_PRODUCTO].estado = false;
        this.$refs.theModalConsumoProducto.detalles[INDEX].estado = false;
      } else {
        // nuevo
        const INDEX = this.consumoProductos.findIndex((el) => el.key === item.key);
        const INDEX_SALIDA_PRODUCTO = this.salidaProductos.detalles.findIndex((el) => el.key === item.key);
        this.consumoProductos.splice(INDEX, 1);
        this.salidaProductos.detalles.splice(INDEX_SALIDA_PRODUCTO, 1);
        this.$emit('delDetalle', INDEX);
      }
    },
    cargarConsumoProductos() {
      this.salidaProductos.salida_producto_id = this.consumoProductos[0].salida_producto_id;
      this.salidaProductos.moneda_id = this.consumoProductos[0].moneda_id;
      this.salidaProductos.cuenta_contable_id = this.consumoProductos[0].cuenta_contable_id;
      this.salidaProductos.total = parseFloat(this.consumoProductos[0]?.total) || 0;

      this.consumoProductos.forEach((ele, index) => {
        this.$set(this.consumoProductos[index], 'estado', true);
        this.$set(this.consumoProductos[index], 'key', `CP- ${ele.id}`);
        const cantidad = parseInt(ele.cantidad, 10) || 0;
        const productoPrecio = parseFloat(ele.producto_precio) || 0;
        const costoUnitario = parseFloat(ele.costo_unitario) || 0;
        const importe = (parseFloat(ele.importe)).toFixed(2) || 0;
        // Calcular total_venta y total
        const totalVenta = productoPrecio * cantidad;
        const total = cantidad * costoUnitario;
        this.salidaProductos.detalles.push({
          id: ele.id,
          salida_producto_id: ele.salida_producto_id,
          producto_id: ele.producto_id,
          consumo_producto_id: ele.consumo_producto_id,
          producto_numero: ele.producto_numero,
          codigo: ele.codigo,
          producto_nombre: ele.producto_nombre,
          producto_precio: ele.producto_precio,
          total_venta: totalVenta,
          unidad_medida_id: ele.unidad_medida_id,
          // unidad_medida_descripcion: unidad,
          descripcion: ele.descripcion,
          glosa: ele.glosa,
          cantidad: parseInt(ele.cantidad, 10),
          tc: parseFloat(ele.tc),
          lote_id: ele.lote_id,
          lote_numero: ele.lote_numero,
          costo_unitario: parseFloat(ele.costo_unitario),
          total,
          tipo_consumo: ele.tipo_consumo,
          indicacion: ele.indicacion,
          via: ele.via,
          estado: true,
          key: `CP- ${ele.id}`,
          importe,
          fecha_hora: ele.fecha_hora,
        });
      });
    },
    setTipoConsumoProducto(tipo) {
      this.tipo_consumo_producto = tipo;
    },
    addServiciosComplemetarios(dtos) {
      const SERVICIOS_COMPLEMENTARIOS = dtos;
      SERVICIOS_COMPLEMENTARIOS.forEach((element, index) => {
        const OBJ = {
          id: 0,
          servicio_complementario_id: SERVICIOS_COMPLEMENTARIOS[index].servicio_complementario_id,
          nombre: SERVICIOS_COMPLEMENTARIOS[index].nombre,
          estado: SERVICIOS_COMPLEMENTARIOS[index].estado,
          cantidad: SERVICIOS_COMPLEMENTARIOS[index].cantidad,
          precio: SERVICIOS_COMPLEMENTARIOS[index].precio,
          descripcion: SERVICIOS_COMPLEMENTARIOS[index].descripcion,
          categoria_serv_compl_id: this.categoriaServicioComplId,
          numero: SERVICIOS_COMPLEMENTARIOS[index].numero,
          categoria: SERVICIOS_COMPLEMENTARIOS[index].categoria,
          key: SERVICIOS_COMPLEMENTARIOS[index].key,
          tipo_consumo_servicio: this.tipo_consumo_producto,
          session: SERVICIOS_COMPLEMENTARIOS[index].session,
          fecha_hora: SERVICIOS_COMPLEMENTARIOS[index].fecha_hora,
        };
        this.servicioComplementario.push(OBJ);
      });
      this.filterListaTempServicioComplementario();
    },
    salidaProductosSendData() {
      this.salidaProductos.moneda_id = this.moneda.model.id;
      this.salidaProductos.cuenta_contable_id = this.dataModalCXP.parametrosContables.cuenta_debe_sp;
      let total = 0;
      for (let index = 0; index < this.salidaProductos.detalles.length;) {
        const ele = this.salidaProductos.detalles[index];
        index += 1;
        ele.fecha = (!ele.created_at) ? this.getFechaFormat(ele.fecha, 'YYYY-MM-DD HH:mm:ss') : ele.created_at;
        const eleTotal = parseFloat(ele.total) || 0;
        total += eleTotal;
        ele.total = eleTotal;
      }

      this.salidaProductos.total = total || 0; // Asegúrate de que total nunca sea null o undefined
      return this.salidaProductos;
    },

    fullformateDate(fecha) {
      const fechaFormat = this.formatearDate(fecha);
      const day = fechaFormat.getDate();
      const month = fechaFormat.getMonth();
      const year = fechaFormat.getFullYear();
      const hora = (fechaFormat.getHours() < 10) ? `0${fechaFormat.getHours()}` : fechaFormat.getHours();
      const minuto = (fechaFormat.getMinutes() < 10) ? `0${fechaFormat.getMinutes()}` : fechaFormat.getMinutes();
      return `${day}-${month}-${year} H:${hora}:${minuto}`;
    },
    openModalPieza() {
      this.$bvModal.show('modal-pieza');
    },
    openModalAutorizacionInternacion() {
      this.medicationsProvidesByPharmacy = true;
      this.$bvModal.show('ModalAutorizacionInternacion');
    },
    openModalCierre() {
      this.$bvModal.show('modal-cierre');
    },
    ...mapMutations('modalPieza', [
      'cargarModalPieza',
      'cargarSalaCamaID',
    ]),
    ...mapActions('modalPieza', [
      'resetModalPieza',
    ]),
    eliminarServiciosInternos(item) {
      const NRO = item.numero;
      if (NRO === '-') {
        const INDEX = this.servicios.findIndex((el) => el.customId === item.customId);
        this.servicios.splice(INDEX, 1);
      } else {
        const INDEX = this.servicios.findIndex((el) => el.customId === item.customId);
        this.servicios[INDEX].venta_servicio_estado = false;
      }
    },
    addServiciosInternos(data) {
      const service = data.servicios;
      this.servicios.push(service);
      // this.$refs.theModalPreCierre.restaurar();
    },
    setServicesData() {
      this.servicios.forEach((item) => {
        this.$set(item, 'venta_servicio_estado', true);
        this.$set(item, 'customId', item.id);
        const glosa = JSON.parse(item.glosas);
        // eslint-disable-next-line no-param-reassign
        item.glosas = glosa;
        // this.serviciosAllInternos.forEach((ele) => {
        //   if (item.tipo_servicio_id === null) {
        //     if (item.honorario_medico_id === ele.id) {
        //       this.$set(item, 'tipo_servicio_nombre', ele.tipo_servicio_nombre);
        //     }
        //   } else if (item.tipo_servicio_id === ele.id) {
        //     this.$set(item, 'tipo_servicio_nombre', ele.tipo_servicio_nombre);
        //   }
        // });
      });
      // this.agregarCategoriaServicios();
      this.convertirAcadenaGlosa();
    },
    convertirAcadenaGlosa() {
      let TEXTOGLOSA = '';
      this.servicios.forEach((ele) => {
        ele.glosas.forEach((item, index) => {
          if (item.nombre != null) {
            if (index === 0) {
              TEXTOGLOSA = item.nombre;
              this.$set(ele, 'textoglosa', TEXTOGLOSA);
            } else {
              TEXTOGLOSA = item.nombre;
              // eslint-disable-next-line no-param-reassign
              ele.textoglosa = `${ele.textoglosa} , ${TEXTOGLOSA}`;
            }
          }
        });
      });
    },
    showModalServicioInterno(dataServicio) {
      const DATA = this.cloneObject(dataServicio);
      this.$refs.servicioInterno.showModalEventEdit(DATA);
    },
    agregarPrecierre(data) {
      this.preCierreData.total_precierre = data.total;
      this.preCierreData.saldo_precierre = data.saldo;
      this.preCierreData.anticipo_precierre = data.anticipo;
      this.preCierreData.glosa_precierre = data.glosa;
      this.preCierreData.estado_precierre = data.estado_pre_cierre;
      this.preCierreData.custom_id = data.custom_id;
      this.preCierreData.hora = data.hora;
      this.preCierreData.fecha = data.fecha;
      this.preCierre = data.isPrecierre;
    },
    agregarCategoriaServicios() {
      this.servicios.forEach((item, index) => {
        this.categoriaServiciosInternos.forEach((ele) => {
          if (item.categoria_medico_id === ele.id) {
            let nombreServicio = item.tipo_servicio_nombre;
            nombreServicio = `${nombreServicio} - ${ele.nombre}`;
            // eslint-disable-next-line no-param-reassign
            this.servicios[index].tipo_servicio_nombre = nombreServicio;
          }
        });
      });
    },
    cargarModalPreCierre(data) {
      if (data.precierre) {
        // true tiene un precierre y esta activo
        this.preCierreData.id = data.id;
        this.preCierreData.total_precierre = data.total;
        this.preCierreData.anticipo_precierre = data.anticipo;
        this.preCierreData.saldo_precierre = data.saldo;
        this.preCierreData.glosa_precierre = data.glosa;
        this.preCierreData.estado_precierre = data.precierre;
        this.preCierreData.fecha = data.fecha;
        this.preCierreData.hora = data.hora;
        this.preCierre = data.precierre !== 0;
        this.preCierreData.usuario = data.usuario;
      } else {
        // tiene precierre pero esta desabiitado
        this.preCierreData.id = data.id;
        this.preCierreData.total_precierre = 0;
        this.preCierreData.anticipo_precierre = (this.dataPaciente.anticipo)
          ? this.dataPaciente.anticipo : 0;
        this.preCierreData.saldo_precierre = 0;
        // this.preCierreData.glosa_precierre = data.glosa;
        this.preCierreData.estado_precierre = data.precierre;
        this.preCierre = data.precierre !== 0;
        this.preCierreData.usuario = data.usuario;
      }
    },
    cargarDatosPreCierre() {
      const TOTAL = parseFloat(this.serviciosInternos) + parseFloat(this.centasXPagar)
      + parseFloat(this.consumoProducto) + parseFloat(this.totalServicioComplementarios)
      + parseFloat(this.totalImporteSalaCama);
      const SALDO = parseFloat(TOTAL) - parseFloat(this.preCierreData.anticipo_precierre);
      this.preCierreData.total_precierre = TOTAL;
      this.preCierreData.saldo_precierre = SALDO;
    },
    limpiarPreCierre() {
      this.preCierreData.total_precierre = 0;
      // this.preCierreData.anticipo_precierre = 0;
      this.preCierreData.saldo_precierre = 0;
      this.preCierreData.glosa_precierre = null;
      this.preCierreData.estado_precierre = false;
      this.preCierreData.custom_id = new Date().getTime();
      // marcar el estado del cierre como false;
      this.cierre.estado = false;
      this.cierre.hora = null;
      this.cierre.glosa = null;
    },
    agregarCierre(data) {
      this.cierre.fecha = data.fecha;
      this.cierre.hora = data.hora;
      this.cierre.glosa = data.glosa;
      this.cierre.estado = data.estado;
    },
    activateTheButton(data) {
      this.nombreButtonActivo = data;
      this.categoriaServicioComplId = null;
      this.$bvModal.hide('theModalProductoServicios');
      this.$bvModal.hide('TheModalFisioterapia');
    },
    async openModalServicios(titleModal, catServicioCompId) {
      this.descripcionTitulo = titleModal;
      this.nombreButtonActivo = titleModal;
      this.categoriaServicioComplId = catServicioCompId;
      await this.resetDataTheModaServios();
      const DTO = {
        catServicioCompId,
        seguro: this.seguro.model,
      };
      await this.getDataServicioComplementario(DTO);
      // ocultar el modal consumoProducto o Farmacia
      this.$refs.theModalConsumoProducto.onCloseModal();
      this.$bvModal.show('theModalProductoServicios');
    },
    async openModalFisioterapia(titleModal, categoriaId) {
      this.descripcionTitulo = titleModal;
      this.nombreButtonActivo = titleModal;
      this.categoriaServicioComplId = categoriaId;
      await this.resetDataTheModaServios();
      const DTO = {
        catServicioCompId: categoriaId,
        seguro: this.seguro.model,
      };
      await this.getDataServicioComplementario(DTO);
      // ocultar el modal consumoProducto o Farmacia
      this.$refs.theModalConsumoProducto.onCloseModal();
      this.$bvModal.show('TheModalFisioterapia');
    },
    onClickButtonEvolucionMedica() {
      this.nombreButtonActivo = 'evolucion_medica';
      this.categoriaServicioComplId = null;
      this.$bvModal.hide('theModalProductoServicios');
      this.$bvModal.hide('TheModalFisioterapia');
      this.openModalEvolucionMedica();
    },
    openModalEvolucionMedica() {
      this.$bvModal.show('TheModalEvolucionMedica');
    },
    openModalCirugia(toAddNewItem) {
      this.modeAddItem = toAddNewItem;
      this.$bvModal.show('modal-cirugia');
    },
    openModalInterConsulta() {
      this.$bvModal.show('modal-interConsulta');
    },
    addItemCirugia(data) {
      const item = {
        id: data.id,
        modeAddCirugia: false,
      };
      this.cirugias.push(item);
    },
    saveData() {
      //
    },
    eliminarServicio(item) {
      const ID = item.id;
      if (ID > 0) {
        const INDEX = this.servicioComplementario.findIndex((el) => (
          el.id === item.id));
        this.servicioComplementario[INDEX].estado = false;
      } else {
        const INDEX = this.servicioComplementario.findIndex((el) => (
          el.key === item.key));
        this.servicioComplementario.splice(INDEX, 1);
      }
      this.filterListaTempServicioComplementario();
    },
    filterListaTempServicioComplementario() {
      switch (this.categoriaServicioComplId) {
        case this.categoriaLaboratorio:
          this.listTempServiciosComplementarios = this.servicioComplementario.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaLaboratorio && obj.estado === true
          ));
          break;
        case this.categoriaEcografia:
          this.listTempServiciosComplementarios = this.servicioComplementario.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaEcografia && obj.estado === true
          ));
          break;
        case this.categoriaRayosX:
          this.listTempServiciosComplementarios = this.servicioComplementario.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaRayosX && obj.estado === true
          ));
          break;
        case this.categoriaTomografia:
          this.listTempServiciosComplementarios = this.servicioComplementario.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaTomografia && obj.estado === true
          ));
          break;
        case this.categoriaMamografia:
          this.listTempServiciosComplementarios = this.servicioComplementario.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaMamografia && obj.estado === true
          ));
          break;
        case this.categoriaArcoC:
          this.listTempServiciosComplementarios = this.servicioComplementario.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaArcoC && obj.estado === true
          ));
          break;
        case this.categoriaFisioterapia:
          this.listTempServiciosComplementarios = this.servicioComplementario.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaFisioterapia && obj.estado === true
          ));
          break;
        case this.categoriaTratam:
          this.listTempServiciosComplementarios = this.servicioComplementario.filter((obj) => (
            obj.categoria_serv_compl_id === this.categoriaTratam && obj.estado === true
          ));
          break;

        default:
          break;
      }
    },
    goToHistorialClinico() {
      const paciente = this.pacienteSelected;
      if (paciente) {
        this.$router.push({
          name: 'historial-clinico',
          params: { id: paciente.id },
        });
      } else {
        util.showNotify('Debe Seleccionar un Paciente', 'warn');
      }
    },
    addEvolucionMedica(dto) {
      const OBJECT = {
        id: dto.id,
        descripcion_medico: dto.descripcion,
        indicacion_medico: dto.indicacion,
        medico: dto.userName,
        estado: dto.estado,
        fecha: dto.fecha,
        hora: dto.hora,
      };
      this.evolucionMedicas.push(OBJECT);
    },
    eliminarEvolucionMedica(item) {
      const ID = item.id;
      if (ID > 0) {
        const INDEX = this.evolucionMedicas.findIndex((el) => (
          el.id === item.id));
        this.evolucionMedicas[INDEX].estado = false;
      } else {
        const INDEX = this.evolucionMedicas.findIndex((el) => (
          el.key === item.key));
        this.evolucionMedicas.splice(INDEX, 1);
      }
    },
    formattedDate(fecha) {
      const date = new Date(fecha);
      return date.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
    clasificacionObtenida(lista) {
      this.procesarClasificacionCIE(this.listaClasificacionCIE);
      const clasificacionId = lista.clasificacion_cie1_id; // Cambia el índice si necesitas un ID específico
      const subClasificacionId = lista.subclasificacion_cie_id;
      const diagnosticoInicial = this.listaClasificacionCIE.find((diagnostico) => diagnostico.id === clasificacionId && diagnostico.id_sub === subClasificacionId);

      this.diagnosticoSeleccionado = diagnosticoInicial || null;
    },
    procesarClasificacionCIE(responseData) {
      const listaOrganizada = [];

      responseData.forEach((diagnostico) => {
        if (diagnostico.sub_clasificacion_cie && diagnostico.sub_clasificacion_cie.length > 0) {
          diagnostico.sub_clasificacion_cie.forEach((sub) => {
            listaOrganizada.push({
              id: diagnostico.id,
              codigo: diagnostico.codigo,
              nombre: diagnostico.nombre,
              id_sub: sub.id,
              sub_codigo: sub.codigo,
              sub_nombre: sub.nombre,
            });
          });
        } else {
          listaOrganizada.push({
            id: diagnostico.id,
            codigo: diagnostico.codigo,
            nombre: diagnostico.nombre,
            id_sub: null,
            sub_codigo: null,
            sub_nombre: null,
          });
        }
      });

      this.listaClasificacionCIE = listaOrganizada;
      this.listaClasificacionCIEFiltrada = listaOrganizada;
    },
    filtrarDiagnosticos(query) {
      if (!query) {
        // Si no hay consulta, mostramos todo
        this.listaClasificacionCIEFiltrada = this.listaClasificacionCIE;
      } else {
        // Filtrar por código, nombre, sub_codigo o sub_nombre
        const queryLower = query.toLowerCase();
        this.listaClasificacionCIEFiltrada = this.listaClasificacionCIE.filter(
          (item) => item.codigo.toLowerCase().includes(queryLower)
            || item.nombre.toLowerCase().includes(queryLower)
            || (item.sub_codigo && item.sub_codigo.toLowerCase().includes(queryLower))
            || (item.sub_nombre && item.sub_nombre.toLowerCase().includes(queryLower)),
        );
      }
    },

    guardarDiagnosticoSeleccionado(diagnostico) {
      if (diagnostico) {
        this.diagnosticoSeleccionado = diagnostico;
      } else {
        this.diagnosticoSeleccionado = null;
      }
    },
  },
  computed: {
    ...mapGetters('modalPieza', [
      'getIDItemChecked',
      'getCamaItemChecked',
      'getPisoSalaItemChecked',
      'getImporteXDiaSalaCama',
    ]),
    categoriaEcografia() {
      return CATEGORIA_SERVICIO_COMPL.ECOGRAFIA;
    },
    categoriaLaboratorio() {
      return CATEGORIA_SERVICIO_COMPL.LABORATORIO;
    },
    categoriaRayosX() {
      return CATEGORIA_SERVICIO_COMPL.RAYOS_X;
    },
    categoriaTomografia() {
      return CATEGORIA_SERVICIO_COMPL.TOMOGRAFIA;
    },
    categoriaMamografia() {
      return CATEGORIA_SERVICIO_COMPL.MAMOGRAFIA;
    },
    categoriaArcoC() {
      return CATEGORIA_SERVICIO_COMPL.ARCO_C;
    },
    categoriaFisioterapia() {
      return CATEGORIA_SERVICIO_COMPL.FISIOTERAPIA;
    },
    categoriaFarmacia() {
      return CATEGORIA_SERVICIO_COMPL.FARMACIA;
    },
    categoriaTratam() {
      return CATEGORIA_SERVICIO_COMPL.TRATAMIENTO;
    },
    isDisabledAlmacen() {
      return this.salidaProductos.detalles.length > 0;
    },
    getClassButtonFarmacia() {
      let classToApply = 'btn-light';
      /* if (this.consulta === null) {
        return classToApply;
      } */
      if (this.nombreButtonActivo === this.getNameFarmacia) {
        classToApply = 'btn-success';
      } else {
        classToApply = 'btn-secondary';
      }
      return classToApply;
    },
    getClassButtonLaboratorio() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameLaboratorio) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonEcografia() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameEcografia) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonRayosX() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameRayosX) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonTomografia() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameTomografia) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonMamografia() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameMamografia) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonArcoC() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameArcoEnC) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonFisioterapia() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameFisioterapia) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    getClassButtonTratamiento() {
      let classToApply = 'btn-secondary';
      if (this.nombreButtonActivo === this.getNameTratamiento) {
        classToApply = 'btn-success';
      }
      return classToApply;
    },
    serviciosFiltered() {
      return this.servicios.filter((item) => item.venta_servicio_estado === true);
    },
    serviciosInternos() {
      let total = 0;
      this.serviciosFiltered.forEach((item) => {
        total += parseFloat(item.importe);
      });
      return total;
    },
    centasXPagar() {
      let total = 0;
      this.cuentas_x_pagar.forEach((item) => {
        const tipoMoneda = this.moneda.multiselect.find(
          (el) => el.id === item.moneda_id,
        );
        if (tipoMoneda.is_principal === 0) {
          total += parseFloat(item.importe) * parseFloat(tipoMoneda.tc);
        } else {
          total += parseFloat(item.importe);
        }
      });
      return total;
    },
    consumoProducto() {
      let total = 0;
      this.salidaProductos.detalles.forEach((item) => {
        if (item.tipo_consumo === 2 && item.estado) {
          total += parseFloat(item.total_venta);
        }
      });
      return total;
    },
    getNameFarmacia() {
      if (this.getItemFarmacia != null) {
        return this.getItemFarmacia.nombre;
      }
      return 'Farmacia';
    },
    getNameLaboratorio() {
      if (this.getItemLaboratorio != null) {
        return this.getItemLaboratorio.nombre;
      }
      return 'Laboratorio';
    },
    getNameEcografia() {
      if (this.getItemEcografia != null) {
        return this.getItemEcografia.nombre;
      }
      return 'Ecografia';
    },
    getNameRayosX() {
      if (this.getItemRayosX != null) {
        return this.getItemRayosX.nombre;
      }
      return 'Rayos X';
    },
    getNameTomografia() {
      if (this.getItemTomografia != null) {
        return this.getItemTomografia.nombre;
      }
      return 'Tomografia';
    },
    getNameMamografia() {
      if (this.getItemMamografia != null) {
        return this.getItemMamografia.nombre;
      }
      return 'Mamografia';
    },
    getNameArcoEnC() {
      if (this.getItemArcoC != null) {
        return this.getItemArcoC.nombre;
      }
      return 'Arco en C';
    },
    getNameFisioterapia() {
      if (this.getItemFisioterapia != null) {
        return this.getItemFisioterapia.nombre;
      }
      return 'Fisioterapia';
    },
    getNameTratamiento() {
      if (this.getItemTratamiento != null) {
        return this.getItemTratamiento.nombre;
      }
      return 'Tratamiento';
    },
    getItemFarmacia() {
      if (this.parametroServicioComplementarioList.length > 0) {
        const item = this.parametroServicioComplementarioList.find((el) => el.servicio_complementario_categoria_id === 8) ?? null;
        return item;
      }
      return null;
    },
    getItemLaboratorio() {
      if (this.parametroServicioComplementarioList.length > 0) {
        const item = this.parametroServicioComplementarioList.find((el) => el.servicio_complementario_categoria_id === 9) ?? null;
        return item;
      }
      return null;
    },
    getItemEcografia() {
      if (this.parametroServicioComplementarioList.length > 0) {
        const item = this.parametroServicioComplementarioList.find((el) => el.servicio_complementario_categoria_id === 1) ?? null;
        return item;
      }
      return null;
    },
    getItemRayosX() {
      if (this.parametroServicioComplementarioList.length > 0) {
        const item = this.parametroServicioComplementarioList.find((el) => el.servicio_complementario_categoria_id === 2) ?? null;
        return item;
      }
      return null;
    },
    getItemTomografia() {
      if (this.parametroServicioComplementarioList.length > 0) {
        const item = this.parametroServicioComplementarioList.find((el) => el.servicio_complementario_categoria_id === 3) ?? null;
        return item;
      }
      return null;
    },
    getItemMamografia() {
      if (this.parametroServicioComplementarioList.length > 0) {
        const item = this.parametroServicioComplementarioList.find((el) => el.servicio_complementario_categoria_id === 4) ?? null;
        return item;
      }
      return null;
    },
    getItemArcoC() {
      if (this.parametroServicioComplementarioList.length > 0) {
        const item = this.parametroServicioComplementarioList.find((el) => el.servicio_complementario_categoria_id === 5) ?? null;
        return item;
      }
      return null;
    },
    getItemFisioterapia() {
      if (this.parametroServicioComplementarioList.length > 0) {
        const item = this.parametroServicioComplementarioList.find((el) => (
          el.servicio_complementario_categoria_id === CATEGORIA_SERVICIO_COMPL.FISIOTERAPIA))
          ?? null;
        return item;
      }
      return null;
    },
    getItemTratamiento() {
      if (this.parametroServicioComplementarioList.length > 0) {
        const item = this.parametroServicioComplementarioList.find((el) => (
          el.servicio_complementario_categoria_id === CATEGORIA_SERVICIO_COMPL.TRATAMIENTO))
          ?? null;
        return item;
      }
      return null;
    },
    isActiveFarmacia() {
      if (this.getItemFarmacia != null) {
        return this.getItemFarmacia.estado;
      }
      return false;
    },
    isActiveLaboratorio() {
      if (this.getItemLaboratorio != null) {
        return this.getItemLaboratorio.estado;
      }
      return false;
    },
    isActiveEcografia() {
      if (this.getItemEcografia != null) {
        return this.getItemEcografia.estado;
      }
      return false;
    },
    isActiveRayosX() {
      if (this.getItemRayosX != null) {
        return this.getItemRayosX.estado;
      }
      return false;
    },
    isActiveTomografia() {
      if (this.getItemTomografia != null) {
        return this.getItemTomografia.estado;
      }
      return false;
    },
    isActiveMamografia() {
      if (this.getItemMamografia != null) {
        return this.getItemMamografia.estado;
      }
      return false;
    },
    isActiveArcoC() {
      if (this.getItemArcoC != null) {
        return this.getItemArcoC.estado;
      }
      return false;
    },
    isActiveFisioterapia() {
      if (this.getItemFisioterapia != null) {
        return this.getItemFisioterapia.estado;
      }
      return false;
    },
    isActiveTratamiento() {
      if (this.getItemTratamiento != null) {
        return this.getItemTratamiento.estado;
      }
      return false;
    },
    evolucionFiltered() {
      return this.evolucionMedicas.filter((item) => item.estado);
    },
    totalServicioComplementarios() {
      let total = 0;
      this.servicioComplementario.forEach((item) => {
        if (item.tipo_consumo_servicio === TIPO_CONSUMO_PRODUCTO_SERVICIO.VENTA) {
          total += parseFloat(item.precio);
        }
      });
      return total;
    },
    filterListaTemporalConsumoProducto() {
      return this.consumoProductos.filter((item) => item.estado === true);
    },
    totalImporte() {
      return this.filterListaTemporalConsumoProducto.reduce((total, producto) => {
        // Asegúrate de que producto.total sea un número válido y sin formato
        const totalProducto = parseFloat(producto.importe) || 0;
        return total + totalProducto;
      }, 0);
    },
    totalImporteSalaCama() {
      let total = 0;
      total = parseInt(this.diasTratamiento, 10) * parseFloat(this.getImporteXDiaSalaCama);
      return total;
    },
  },
  watch: {
    'dataPaciente.cobertura': {
      handler(newSeguro) {
        if (newSeguro) {
          const seguroEnLista = this.seguro.listaSeguros.find((seguro) => seguro.nombre_comercial === newSeguro);
          if (seguroEnLista) {
            this.seguro.model = seguroEnLista;
          } else {
            this.seguro.model = null;
          }
        }
      },
      deep: true, // Observa cambios profundos dentro de dataPaciente.cobertura
    },
    categoriaServicioComplId(new1) {
      if (new1) {
        this.filterListaTempServicioComplementario();
      }
    },
  },
};
</script>

<style scoped>
.btn-white{
  background: white;
  color: black;
}
.text-skyblue
{
  color: #61c6f2;
}
.bg-sky-light
{
  background:#a6d2eb;
}
.navbar-reubicado
{
  transform: translate(0px, -32px);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.tabla-scroll-vertical {
    overflow-y: scroll;
    height: 180px;
}
.btnBlue {
  color: white;
  background-color: #47404f;
}
.btnOrange {
  color: white;
  background-color: #F4B375;
}
.btnTurquesa {
  color: white;
  background-color: #319ba1;
}
.bg-abierto {
  background: #f8cb9e;
}
.custom-small-text {
  font-size: 8px; /* Ajusta según lo que necesites */
}
</style>
